
import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import cover1 from "./cover1.jpg"
import logo from "./logo.png"
import cover2 from "./cover2.jpg"
import cover3 from "./cover3.jpg"
import cover4 from "./cover4.jpg"
import l1 from "./l1.jpg"
import icon1 from "./icon1.png"
import impact2 from "./r1.jpg"
import impact3 from "./imp3.jpg"
import impact4 from "./imp4.jpg"
import r2 from "./r2.jpg"
import globalImage from "./global.svg"
import { Accordion, AccordionItem as NewItem } from '@szhsin/react-accordion'
import axios from "axios"
import f1 from "./f1.mp4"
import phone2 from "./phone2.png"
import d4 from "./d4.jpg"
import icon2 from "./icon2.png"
import { useHistory } from "react-router-dom"

export default function Landing() {
    useEffect(() => {
        const scEleTwo = document.getElementById('scEleTwos')
        const scEleTwoWidth = scEleTwo.scrollWidth
        setInterval(() => {
            if (scEleTwo.scrollLeft < scEleTwoWidth) {
                scEleTwo.scrollTo(scEleTwo.scrollLeft + 1, 0)  
            }
        }, 15)
    }, [])

    const history = useHistory()

    
    const AccordionItem = ({ header, ...rest }) => (
        <NewItem
          {...rest}
          header={
            <Flex align="center" w="100%" mb={["-2", "0"]}>
              <Text className="font" fontSize={["16px", "18px"]} fontWeight="400">{header}</Text>
              <i className='mdi mdi-chevron-down chevron-down' style={{ fontSize: '25px' }}></i>
            </Flex>
          }
        />
    );

    const [newsData, setNewsData] = useState("")
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
   
    useEffect(async () => {
        const url = 'https://api.nytimes.com/svc/search/v2/articlesearch.json?q=women&fq=health&api-key=VvG8seu6yGiJJzm95XyvV9kjVwXtrecw&page='+page
        setLoading(true)
        const res = await axios.get(url)
        setNewsData(res?.data?.response?.docs)
        setLoading(false)
    }, [page])

    const [mod1, setMod1] = useState(false)
    const [modArr, setModArr] = useState("")



   
    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" minH="100vh" bg={["#000", "rgb(250,250,251)"]} overflowX="hidden" direction={["column", "row"]}>
                <Flex w={["100%", "50%"]} h={["58%", "100%"]} bg="rgb(250,250, 251)" py={["20px", "50px"]} pl={["5", "8%"]} pr={["5", "6%"]} direction="column">
                    <Flex flex="1" direction="column" justify="center" pt={["70px", "0"]}>
                        <Flex data-aos="fade-down" w={["71px", "101px"]} h={["70px", "100px"]} bgColor="rgba(100, 100, 100, .1)" mb={["3", "4"]} backgroundImage={logo} backgroundSize="65%" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="center center">
                        </Flex>

                        <Text fontSize={["28px", "38px"]} data-aos="fade-up"><Text fontWeight="700" as="span">Love For </Text>Health <Text as="span" fontSize={["24px", "34px"]}>(LHO)</Text>.</Text>
                        <Text mt={["1.5", "2"]} data-aos="fade-up" transition="150ms ease-in-out" fontSize={["14px", "15px"]}><Text>“Caring for women”</Text> <Text>Free, accessible, and affordable healthcare services.</Text></Text>
                        
                        <Flex mt={["8", "10"]} align="center" data-aos="fade-up">
                            <Text w={["30px", "50px"]} h="2px" bg="#000" mr="2"></Text>
                            <Flex mr={["2", "4"]} border="2px solid #000" padding={["10px 25px", "16px 34px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/contact-us")}>Reach Out <Text ml="2">➜</Text></Flex>

                            <Flex border="2px solid #000" padding={["10px 28px", "16px 34px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/booking")}>Book <Text ml="2">➜</Text></Flex>
                        </Flex>
                    </Flex>
                </Flex>


                <Flex display={["flex", "flex"]} w={["100%", "50%"]} h={["42%", "100%"]} backgroundColor="#000" backgroundImage={cover1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" data-aos="fade-left">
                    <Flex w="100%" h="100%" overflow="hidden" bg="rgba(0,0,0,.65)" direction="column" px={["2", "3%"]} py={["0", "50px"]} color="#fff">
                        <Flex flex="1" direction="column" justify="center" mt={["0", "80px"]}>
                            <Flex w="100%" overflowX="scroll" className="noscbar" id="scEleTwos" borderRadius="8px">
                                <Flex flexWrap="nowrap" minW={["1030px", "1690px"]} align="center" mt={["0", "8"]} overflowX="scroll" className="noscbar">
                                    {
                                        [{name: "Free Clinical Services for Women", sub: "At Love for Health Organization (LHO), we provide free clinical services to address the healthcare struggles faced by women globally.", bg: cover2},{name: "Gender-Based Violence Support", sub: "We recognize the pervasive issue of gender-based violence globally and the significant impact it has on women's health and well-being.", bg: cover3},{name: "Advocacy and Education", sub: "We believe that education and advocacy are key to creating a society where women and the girl child can thrive", bg: cover4},].map((item, index) => (
                                            <Flex key={index} ml={[index === 0 ? "50px" : "10px", index === 0 ? "130px" : "30px"]} bg="rgba(255, 255, 255, .21)" borderRadius="8px" w={["320px", "500px"]} h={["280px", "500px"]} py={["6", "8"]} px={["6", "8"]} direction="column">
                                                <Flex justify="center" align="center" fontWeight="700" w="41px" h="40px" fontSize="20px" bg="rgba(255, 255, 255, .31)" borderRadius="100%">0{index+1}</Flex>
                                                <Flex mt="auto" w="100%" direction="column">
                                                    <Text mb="1" fontWeight="600">{item.name}</Text>
                                                    <Text mb="5" fontSize="13px">{item.sub}</Text>
                                                    <Flex w="100%" h="270px" display={["none", "flex"]} backgroundImage={item.bg} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius="8px" />
                                                </Flex>
                                            </Flex>
                                        ))
                                    }
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>





            <Flex bg="#fff" py={["60px", "80px"]} px={["5", "8%"]} direction={["column-reverse", "row"]} align={["flex-start", "center"]} justify="space-between">
                <Flex display={["none", "flex"]} w={["100%", "44%"]} data-aos="fade-up">
                    <Flex w="100%" backgroundImage={l1} h={["300px", "550px"]} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center left"></Flex>
                </Flex>
                <Flex w={["100%", "50%"]} direction="column">
                    <Flex align="center" data-aos="fade-down">
                        <Image src={icon1} w={["30px", "50px"]} />
                        <Text ml="4" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">What </Text>We do</Text>
                    </Flex>
                    <Text bg="rgb(202, 202, 205)" h="1px" mt="4" mb="6"></Text>
                    <Flex mb="5" display={["flex", "none"]} w={["100%", "44%"]} data-aos="fade-up">
                        <Flex w="100%" backgroundImage={l1} h={["300px", "550px"]} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center left"></Flex>
                    </Flex>
                    <Text fontSize="14px" color="rgb(102, 112, 133)" data-aos="fade-up">At LHO we provide a wide range of services to support women's health, including free clinical services, reproductive health services, prenatal care, and mental health services. We provide reliable and quality health information, management tips, and support to women in need. We also engage in advocacy and sensitization and channel concerted efforts to fight against gender-based violence, including rape and discrimination. We believe that every woman deserves access to quality healthcare and support, regardless of their financial status</Text>
                    <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                        <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/about-us")}>About Us <Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
            </Flex>





            <Flex py={["60px", "80px"]} bg="rgb(250,250,251)" w="100%" align="center" direction="column" px={["0", "8%"]}> 
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Our</Text> Impacts</Text>
                <Text mt="3" fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" px={["2", "20%"]} textAlign="center">We are making a difference in the lives of women in Nigeria, Africa and the world at large by attending to their health needs and promoting advocacy</Text>

                <Flex w="100%" mt="12" justify="space-between" direction={["column-reverse", "row"]}>
                    <Flex w={["100%", "53%"]} flexWrap="wrap">
                        {
                            [{ini: "Caring for women", name: "free clinical services to thousands of women", bg: impact2}, {ini: "Empowering", name: "survivors of gender-based violence.", bg: impact3}, {ini: "Awareness", name: "raising awareness about women's health issues", bg: impact4}].map((item, index) => (
                                <Flex data-aos={index === 0 ? "fade-right" : index === 1 ? "fade-down" : "fade-up"} mb={["0", index === 0 && "30px"]} mr={["0", index === 1 && "4%"]} key={index} w={["100%", index === 0 ? "100%" : "48%"]} px="5" h={[index === 0 ? "300px" : "270px", "400px"]} py="5" backgroundColor="#000" backgroundImage={item.bg} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center">
                                    <Flex mt="auto" backdropFilter="blur(12px)" w="100%" bg="rgba(255,255,255,.6)" py="4" px="4" direction="column" color="#000">
                                        <Text fontSize="17px" fontWeight="600">0{index + 1}. {item.ini}</Text>
                                        <Text fontSize="13px">{item.name}</Text>
                                    </Flex>
                                </Flex>
                            ))
                        }
                    </Flex>

                    <Flex w={["100%", "45%"]} data-aos="fade-up" backgroundImage={r2} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center">
                        <Flex w="100%" h="100%" py={["12", "8"]} bg="rgba(0,0,0,0.6)" px="8" direction="column" justify="center" color="#fff">
                            <Text fontWeight="700" fontSize="50px">LHO <Text fontSize="30px">(7k+ health care)</Text></Text>
                            <Text mt="3" fontSize="14px">We have delivered more than 7000 health care services through our dedicated team consisting of experienced health professionals, including doctors, nurses, counsellors, and advocates. Together, we bring a wealth of knowledge and expertise to address the healthcare struggles faced by women in globally</Text>
                            <Flex mt="12" align="center" justify="flex-end">
                                <Text w="50px" h="2px" bg="#fff" mr="2"></Text>
                                <Flex border="2px solid #fff" padding={["10px 30px", "10px 20px"]} cursor="pointer" borderRadius="30px" color="#fff" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/our-services")}>Our Services<Text ml="2">➜</Text></Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>



            <Flex py={["80px", "60px"]} bgColor="rgba(35, 166, 35, .25)" backgroundImage={globalImage} backgroundRepeat="no-repeat" backgroundSize={["120%", "40%"]} backgroundPosition="bottom right" align="center" px={["8", "20%"]} justify="space-between">
                <Flex direction="column" align="center" data-aos="fade-down"><Text fontSize={["48px", "100px"]} fontWeight="700">7k+</Text><Text fontSize={["13px", "15px"]}>cared for</Text></Flex>
                <Flex direction="column" align="center" data-aos="fade-up"><Text fontSize={["48px", "100px"]}fontWeight="700">16k+</Text><Text fontSize={["13px", "15px"]}>outreachs</Text></Flex>
                <Flex direction="column" align="center" data-aos="fade-down"><Text fontSize={["48px", "100px"]}fontWeight="700">2k+</Text><Text fontSize={["13px", "15px"]}>members</Text></Flex>
            </Flex>



            
            <Flex py={["60px", "80px"]} bg="#fff" w="100%" align="center" direction="column" px={["5", "8%"]}> 
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">The Need</Text> and FAQ's</Text>
                <Text mt="3" data-aos="fade-up" fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" px={["2", "20%"]} textAlign="center">Women in Africa face significant challenges in accessing quality healthcare due to limited healthcare facilities and skilled healthcare professionals, especially in rural areas</Text>

                <Flex mt={["0", "6"]} direction="column" w="100%" px={["5", "15%"]} data-aos="fade-up">
                    <Accordion transition transitionTimeout={200}>
                        <AccordionItem header="Expand our reach">
                            With your support, we can extend our free clinical services to more women in need, particularly in underserved rural areas where access to healthcare is limited
                        </AccordionItem>

                        <AccordionItem header="Enhance support for survivors of gender-based violence">
                            We are able to provide comprehensive support services, including counselling, medical care, and legal aid, to more survivors of gender-based violenc
                        </AccordionItem>

                        <AccordionItem header="Scale up advocacy effort">
                            With increased resources, we can amplify our advocacy initiatives and create lasting change by promoting policies and programs that prioritize women's health and rights
                        </AccordionItem>
                        <AccordionItem header="Is our service free?">
                            Yes
                        </AccordionItem>
                        <AccordionItem header="What services do we offer">
                            Free Clinical Services for Women, Gender-Based Violence Support, Advocacy and Education.
                        </AccordionItem>
                    </Accordion>
                </Flex>
            </Flex>


            <Flex py={["60px", "80px"]} bg="rgb(250,250,252)" w="100%" align="center" direction="column" px={["5", "8%"]}> 
                <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">LHO Health</Text> Daily</Text>
                <Text mt="3" data-aos="fade-up" fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" px={["2", "20%"]} textAlign="center" id="are2"> Daily health related articles from over a thousand medical authors.</Text>

                <Flex direction="column" w="100%" mt={["6", "8"]}>
                    {!loading && <Flex w="100%" mb={["10", "10"]} justify="center" align="center" data-aos="fade-up">
                        <Text ml="auto"></Text>
                        <Text w={["30px", "50px"]} h="2px" bg="#000" mr="2"></Text>
                        <Flex border="2px solid #000" padding={["11px 21px", "18px 35px"]} fontSize={["13px", "15px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/articles")}>Our Tweets<Text ml="2">➜</Text></Flex>
                        <Flex ml={["2", "4"]} fontSize={["13px", "15px"]} border="2px solid #000" padding={["11px 21px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                            history.push("/articles")
                            setTimeout(function() {
                                const element = document.getElementById('blogArea')
                                return window.scrollTo({
                                    top: element ? element.offsetTop - 120 : 0,
                                    behavior: 'smooth',
                                })
                            }, 100)
                        }}>Articles<Text ml="2">➜</Text></Flex><Text display={["none", "block"]} w={["30px", "50px"]} h="2px" bg="#000" ml="2"></Text>
                    </Flex>}
                    {
                        loading ? 
                        <Flex justify="center"  py="12"><Spinner color="#000" emptyColor="lightgrey" size="xl" /></Flex> :
                        Array.isArray(newsData) &&
                        newsData.map((item, index) => (
                            <Flex direction={["column", "row"]} key={index} data-aos="fade-up" w="100%" boxShadow="0px 0px 10px rgba(0,0,0,0.2)" mb="7">
                                <Flex bgColor="#000" w={["100%", "40%"]} h={["200px", "auto"]} backgroundImage={"https://www.nytimes.com/"+item?.multimedia[0]?.url} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center"></Flex>

                                <Flex flex="1" py={["5", "8"]} px={["5", "8"]} direction="column" bg="#fff">
                                    <Text fontSize={["17px", "22px"]} fontWeight="700" cursor="pointer" _hover={{ textDecoration: "underline" }} onClick={() => {
                                        setModArr(item)
                                        setMod1(true)
                                    }}>{item?.headline?.main}</Text>
                                    <Text mt={["3", "4"]} color="rgb(102, 112, 133)" fontSize={["15px", "18px"]}>{item?.snippet?.substr(0,200)}....</Text>
                                    <Flex w="100%" color="rgb(102, 112, 133)" mt="7" fontSize={["10px", "15px"]} justify={["space-between", "flex-start"]}>
                                        <Flex align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-calendar-clock"></i></Text> <Text ml="2">{item?.pub_date?.substr(0,10)}, {item?.pub_date?.substr(11,5)}</Text></Flex>
                                        <Flex ml="8" align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-account-outline"></i></Text> <Text ml="2" textTransform="capitalize" wordBreak="break-word">{item?.byline?.original ? item?.byline?.original?.toLowerCase() : "Author 0"+index}</Text></Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
                {!loading && <Flex w="100%" mt={["6", "10"]} justify="center" align="center" data-aos="fade-up">
                    <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["15px 30px", "18px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                        setPage(parseInt(page) + parseInt(1))
                        const element = document.getElementById('are2')
                        return window.scrollTo({
                            top: element ? element.offsetTop - 200 : 0,
                        })
                    }}>Next<Text ml="2">➜</Text></Flex>
                </Flex>}

                {
                    mod1 &&
                    <Flex w="100%" h="100%" position="fixed" top="0" left="0" zIndex="100000" bg="rgba(0,0,0,0.5)" backdropFilter="blur(12px)" justify="center" align="center">
                        <Flex w={["90%", "800px"]} data-aos="fade-up" bg="#fff" direction="column">
                            <Flex bgColor="#000" w="100%" h="200px" backgroundImage={"https://www.nytimes.com/"+modArr?.multimedia[0]?.url} backgroundSize="cover" backgroundRepeat="no-repeat" justify="flex-end" pt="4" pr="4" backgroundPosition="center center">
                                <Flex bg="#fff" justify="center" align="center" color="#000" fontSize={["20px", "30px"]} cursor="pointer" borderRadius="100%" h={["40px", "50px"]} w={["41px", "51px"]} onClick={() => setMod1(false)}><i className="mdi mdi-close"></i></Flex>
                            </Flex>

                            <Flex flex="1" mt="6" p={["8", "12"]} maxH={["60vh", "50vh"]} overflowY="scroll" direction="column" bg="#fff">
                                <Text fontSize={["17px", "22px"]} fontWeight="700" cursor="pointer">{modArr?.headline?.main}</Text>
                                <Text mt={["3", "4"]} color="rgb(102, 112, 133)" fontSize={["15px", "18px"]}>{modArr?.lead_paragraph}</Text>
                                <Flex w="100%" color="rgb(102, 112, 133)" mt="7" fontSize={["10px", "15px"]} justify={["space-between", "flex-start"]}>
                                    <Flex align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-calendar-clock"></i></Text> <Text ml="2">{modArr?.pub_date?.substr(0,10)} @{modArr?.pub_date?.substr(11,5)}</Text></Flex>
                                    <Flex ml="8" align="center"><Text fontSize={["20px", "25px"]}><i className="mdi mdi-account-outline"></i></Text> <Text ml="2" textTransform="capitalize" wordBreak="break-word">{modArr?.byline?.original ? modArr?.byline?.original?.toLowerCase() : "Author 00"}</Text></Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                }
            </Flex>

            


            <Flex w="100%" h="100%" bg="#fff" px={["5", "8%"]} align="center" color="#000" py={["60px", "100px"]} direction={["column", "row"]}>
                <Flex w="48%" data-aos="fade-up" display={["none", "flex"]}>
                    <video loop controls muted playsInline autoPlay style={{ background: "#000", width: "100%", borderRadius: "10px", objectFit: "cover"}}><source src={f1} /></video>
                </Flex>
                <Flex w={["100%", "52%"]} direction="column" pl={["0", "12"]}>
                    <Image src={icon2} data-aos="fade-down" w="50px" mb="3" />
                    <Text data-aos="fade-down" fontSize={["26px", "60px"]} fontWeight="700">Featured <Text fontWeight="300" as="span">Weekly</Text></Text>
                    <Text mt="2" color="rgb(102, 112, 133)" fontSize={["17px", "20px"]} fontWeight="500" data-aos="fade-up">Breast Cancer Awareness</Text>
                    <Text mt="2" color="rgb(102, 112, 133)" data-aos="fade-up">By Ada Anambra</Text>
                    <Text bg="rgb(202, 202, 205)" h="1px" data-aos="fade-up" mt="4" mb="6"></Text>

                    <Flex w="100%" px="10%" mb="8" data-aos="fade-up" display={["flex", "none"]}>
                        <video loop controls muted playsInline autoPlay style={{ background: "#000", width: "100%", borderRadius: "10px", objectFit: "cover"}}><source src={f1} /></video>
                    </Flex>
                    
                    <Text fontSize={["14px", "17px"]} data-aos="fade-up" color="rgb(102, 112, 133)">Every woman has 98% chance of beating breast cancer if being detected at an early stage, always examine your breast and quickly speak to a Doctor after any little sign of discomfort.</Text>
                    <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                        <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/contact-us")}>Reach Out <Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex py={["60px", "80px"]} bg="rgb(250,250,252)" w="100%" align="center" direction="column" px={["5", "8%"]}> 
                <Text data-aos="fade-down" fontSize={["20px", "40px"]}><Text fontWeight="700" as="span">In Need Of</Text> Health Service?</Text>
               

                <Flex w="100%" mt="8" align="center" direction={["column-reverse", "row"]}>
                    <Flex w={["100%", "55%"]} direction="column" pl={["3", "5%"]} pr={["3", "10%"]}>   
                        <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Reach </Text> Out</Text>
                        <Text mt="3" data-aos="fade-up" fontSize="14px" color="rgb(102, 112, 133)">Together, we bring a wealth of knowledge and expertise to address the healthcare struggles faced by women globally. Our team is committed to providing compassionate care, support, and advocacy for women's health and rights all for free.</Text>
                        <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                            <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/booking")}>Get a Doctor <Text ml="2">➜</Text></Flex>
                        </Flex>
                    </Flex>
                    <Flex mb={["8", "0"]} w={["100%", "45%"]} px={["10%", "0"]} data-aos="fade-down">
                        <Image w="100%" src={phone2} filter="drop-shadow(0 0px 10px rgba(0,0,0,.55))" />
                    </Flex>
                </Flex>
            </Flex>

            <Flex py={["60px", "80px"]} bg="#fff" w="100%" align="center" direction="column" px={["5", "8%"]}> 
                <Flex w="100%" align="center" direction={["column", "row"]}>
                    <Flex w={["100%", "45%"]} h={["300px", "400px"]} borderRadius="8px" data-aos="fade-down" backgroundImage={d4} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center">
                    </Flex>
                    
                    <Flex mt={["8", "0"]} w={["100%", "55%"]} direction="column" px={["0", "5%"]}>   
                        <Text data-aos="fade-down" fontSize={["26px", "40px"]}><Text fontWeight="700" as="span">Join Us</Text> or Support</Text>
                        <Text bg="rgb(202, 202, 205)" h="1px" mt="4" mb="6"></Text>
                        <Text data-aos="fade-up" fontSize="14px" color="rgb(102, 112, 133)">We can't do it alone! Your contribution will help us reduce maternal mortality rates, improve access to healthcare, support survivors of gender-based violence, and empower women through education and advocacy. We will provide regular updates on our programs and outcomes, and ensure transparent reporting on how your support is making a difference.</Text>
                        <Flex w="100%" mt="10" justify="flex-end" align="center" data-aos="fade-up">
                            <Text w="50px" h="2px" bg="#000" mr="2"></Text><Flex bg="#fff" border="2px solid #000" padding={["10px 30px", "14px 32px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/donations")}>See our proposal <Text ml="2">➜</Text></Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}