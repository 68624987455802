import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import vid2 from "./vid2.mp4"
import vid3 from "./vid3.mp4"
import vid4 from "./vid4.mp4"
import { useHistory } from "react-router-dom"


export default function Services({ screen, setScreen, setBookNo }) {
    const history = useHistory()

    useEffect(() => {
        var ele = document.getElementById('vidBg')
        if(screen === 0) {
            ele.src = vid2
        }
        else if(screen === 1) {
            ele.src = vid3
        }
        else {
            ele.src = vid4
        }
    }, [screen])

    return (
        <Flex w="100%" h="100vh">
             <video id="vidBg" loop muted playsInline autoPlay style={{ background: "#000", position: "fixed", minWidth: "100%", minHeight: "100%", top: "0", left: "0", objectFit: "cover"}}  src={vid2}></video>



             <Flex w="100%" position="fixed" top="0" left="0" h="100%" bg="rgba(0,0,0,.5)" zIndex="10" px={["5", "8%"]} justify="flex-end" align="flex-end" pb={["0", "12"]}>
                <Flex bg="rgba(255, 255, 255, .18)" py="7" px="5" color="#fff" w="45%" direction="column" data-aos="fade-down" display={["none", "flex"]}>
                    <Text data-aos="fade-down" fontSize="20px" fontWeight="300">About <Text fontWeight="700" as="span">{screen === 0 ? "Free Clinical Services for Women" : screen === 1 ? "Gender-Based Violence Support" : "Advocacy and Education"}</Text></Text>
                    <Text mt="4" fontSize="14px">{screen === 0 ? "At Love for Health Organization (LHO), we provide free clinical services to address the healthcare struggles faced by women in Nigeria. Our team of dedicated health professionals offers a range of medical services, including family planning, antenatal care, cervical cancer screening, and treatment for sexually transmitted infections (STIs)." : screen === 1 ? "We recognize the pervasive issue of gender-based violence in Nigeria and the significant impact it has on women's health and well-being. That's why we provide support for survivors of rape and other forms of gender-based violence. Our team offers counselling, medical care, and legal support to help survivors recover from trauma and seek justice." : "At LHO, we believe that education and advocacy are key to creating a society where women and the girl child can thrive. We offer education and training programs on topics such as reproductive health, gender equality, and women's rights. Additionally, we advocate for policies and programs that improve the health and well-being of women in Nigeria."}</Text>
                    
                    <Flex mt="5" align="center" justify="flex-end">
                        <Text w="50px" h="2px" bg="#fff" mr="2"></Text>
                        <Flex border="2px solid #fff" padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="30px" color="#fff" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                            setBookNo(screen)
                            history.push("/booking")
                        }}>Proceed<Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
             </Flex>




            <Flex w={["81%", "500px"]} px={["5", "8"]} ml={["0", "6%"]} position="absolute" top="0" left="0" minH="100vh" bg={["rgba(255,255,255,0.9)", "#fff"]} zIndex="10000" direction="column" pt="50px" pb="40px">
                <Flex mb="8">
                    <Flex align="center" fontWeight="600" cursor="pointer" onClick={() => history.goBack()}>
                        <Text fontSize="30px"><i className="mdi mdi-chevron-left"></i></Text>
                        <Text ml="2">Go Back</Text>
                    </Flex>
                </Flex>
                <Text data-aos="fade-down" fontSize={["26px", "40px"]} fontWeight="700">Our <Text fontWeight="300" as="span">Services</Text></Text>

                <Flex direction="column" w="100%" data-aos="fade-up">
                    <Text mt="8">At <Text fontWeight="700" as="span">Love for Health Organization (LHO)</Text>, we believe that every woman deserves access to quality healthcare and support, regardless of their financial status. </Text>
                    <Text color="rgb(102, 112, 133)" mt="8" mb={["2", "0"]}>Kindly select a service below</Text>

                    <Flex direction="column" mt="4">
                        {
                            [{name: "Free Clinical Services for Women", sub: "we provide free clinical services to address the healthcare struggles faced by women globally"}, {name: "Gender-Based Violence Support", sub: "we provide support for survivors of rape and other forms of gender-based violence. "}, {name: "Advocacy and Education", sub: "we advocate for policies and programs that improve the health and well-being of women globally"}].map((item, index) => (
                                <>
                                    <Flex key={index} align="center" cursor="pointer" _hover={{ color: "#0e8a3a" }} transition="200ms ease-in-out" onClick={() => setScreen(index)} color={screen === index ? "#0e8a3a" : "#000"} mb="3">
                                        <Flex justify="center" align="center" fontWeight="700" w="41px" h="40px" fontSize="20px" border={screen === index ? "2px solid #0e8a3a" : "2px solid #000"} borderRadius="100%"><i className={index === 0 ? "mdi mdi-hospital" : index === 1 ? "mdi mdi-school" : "mdi mdi-human-handsdown"}></i></Flex>
                                        <Flex flex="1" ml="4"><Text>
                                            <Text fontSize={["14px", "17px"]} fontWeight="600">{item.name}</Text>
                                        </Text></Flex>
                                        <Flex align="center" ml="3" fontSize="20px"><i className="mdi mdi-chevron-right"></i></Flex>
                                    </Flex>
                                    {index < 2 &&<Flex mb="3" h="20px" ml="5" borderLeft="2px solid #000"></Flex>}
                                </>
                            ))
                        }
                    </Flex>
                    <Flex mt="8" display={["flex", "none"]} align="center" justify="center">
                        <Flex border="2px solid #000" padding={["10px 30px", "15px 35px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                            setBookNo(screen)
                            history.push("/booking")
                        }}>Proceed<Text ml="2">➜</Text></Flex>
                    </Flex>
                </Flex>
             </Flex>
        </Flex>
    )
}