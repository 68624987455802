import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import b1 from "./b1.jpg"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import star from "./star.png"
import Toast from "./toast";
import { useHistory } from "react-router-dom";
import logo from "./logo.png"


export default function Booking({ bookNo, setBookNo }) {
    const [screen, setScreen] = useState(0)
    useEffect(() => {
        if(screen === 0) {
            setBookNo(bookNo)
            setData1(bookNo === 0 ? "Free Clinical Services for Women" : bookNo === 1 ? "Gender-Based Violence Support" : "Advocacy and Education")
        }
        else {
            setBookNo("")
        }
    }, [screen])

    const history = useHistory()
    const [loading, setLoading] = useState(false)

    const [data1, setData1] = useState("")
    const [data2, setData2] = useState("")
    const [data3, setData3] = useState(new Date())
    const [data4, setData4] = useState("")
    const [data5, setData5] = useState("")
    const [data6, setData6] = useState("")
    const [data7, setData7] = useState("")

    function nextFunc() {
        if(screen === 0 && data1 !== "") {
            setScreen(1)
        }
        else if(screen === 1 && data2 !== "") {
            setScreen(2)
        }
        else if(screen === 2 && data3 !== "") {
            setScreen(3)
        }
        else if(screen === 3 && data4 !== "") {
            setScreen(4)
        }
        else if(screen === 4 && data5 !== "" && data6 !== "" && data7 !== "") {
            setLoading(true)
            setTimeout(function() {
                setLoading(false)
                Toast("Appointment booked successfully!", "success")
                setScreen(5)
            }, 1500)
        }
    }

    function backFunc() {
        if(screen === 1) {
            setScreen(0)
        }
        if(screen === 2) {
            setScreen(1)
        }
        if(screen === 3) {
            setScreen(2)
        }
        if(screen === 4) {
            setScreen(3)
        }
    }

    return (
        <Flex w="100%" direction="column"> 
            <Flex w="100%" minH="100vh" backgroundColor="#000" backgroundImage={b1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="bottom center">
                <Flex w="100%" h="100%" bg="rgba(0,0,0,0.7)" pt={["120px", "150px"]} pb="40px" px={["5", "8%"]} direction={["column", "row"]}>
                    <Flex w={["100%", "48%"]} pb="4%" h={["auto", "100%"]} pr={["0", "8%"]} direction="column" justify="center" color="#fff">
                        <Flex data-aos="fade-down" w={["71px", "81px"]} h={["70px", "80px"]} bgColor="rgba(100, 100, 100, .4)" mb={["3", "4"]} backgroundImage={logo} backgroundSize="65%" backgroundRepeat="no-repeat" borderRadius="100%" backgroundPosition="center center">
                        </Flex>
                        <Text data-aos="fade-down" fontSize={["30px", "50px"]} fontWeight="700">Love For <Text fontWeight="300" as="span">Health</Text></Text>
                        <Text fontSize={["18px", "25px"]} mt="3" data-aos="fade-up" color="rgb(240,240,240)">
                            Book an appointment today..
                        </Text>
                        <Text color="rgb(240,240,240)" data-aos="fade-up" mt="2" fontSize={["14px", "15px"]}>Our team of dedicated health professionals offers “care to women” ranging from family planning, antenatal care, cervical cancer screening etc.</Text>
                    </Flex>








                    <Flex mt={["5", "0"]} w={["100%", "52%"]} h="100%" data-aos="fade-up"  py={["6", "10"]} px={["5", "10"]} bg="rgba(255,255,255,.7)" backdropFilter="blur(12px)" borderRadius="8px" color="#000" direction="column">
                        
                        {screen !== 5 && <><Text mb="4" fontSize="20px" fontWeight="700">Booking <Text fontWeight="300" as="span">Details</Text></Text>
                        <Flex align="center" mb="10">   
                            <Flex justify="center" align="center" fontWeight="700" w="31px" h="30px" fontSize="18px" bg="rgba(0,0,0, .08)" borderRadius="100%">0{screen + 1}</Flex>
                            <Flex flex="1" ml="3">{screen === 0 ? "Select a service" : screen === 1 ? "Select an assistant" :  screen === 2 ? "Pick an appointment day" : screen === 3 ? "Pick an appointment time" : screen === 3 ? "Fill in your details" : ""}</Flex>
                        </Flex></>}
                        {
                            screen === 0 ?
                            <Flex direction="column">
                                {["Free Clinical Services for Women", "Gender-Based Violence Support", "Advocacy and Education"].map((item, index) => (
                                    <Flex align="center" key={index} mb="4" cursor="pointer" onClick={() => {
                                        setData1(item)
                                        setBookNo(index)
                                    }}>
                                        <Flex border={bookNo === index ? "2px solid #0e8a3a" : "2px solid transparent"} mb="3" justify="center" align="center" fontWeight="700" w="31px" h="30px" fontSize="18px" bg="rgba(0,0,0, .08)" borderRadius="100%">{bookNo === index && <Flex bg="#0e8a3a" w="15px" h="15px" borderRadius="100%"></Flex>}</Flex>
                                        <Flex flex="1" direction="column" ml="4" borderBottom="1px solid rgb(150,150,150)" pb="3">
                                            <Text mb="1" fontSize={["14px", "17px" ]} fontWeight="500">{item}</Text>
                                            <Text fontSize={["12px", "15px"]}>Duration: 30 Mins</Text>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                            :
                            screen === 1 ?
                            <Flex direction="column">
                                {["Choose for me", "Dr. Haroun"].map((item, index) => (
                                    <Flex align="center" key={index} mb="4" cursor="pointer" onClick={() => {
                                        setData2(item)
                                        setBookNo(index)
                                    }}>
                                        <Flex border={bookNo === index ? "2px solid #0e8a3a" : "2px solid transparent"} mb="3" justify="center" align="center" fontWeight="700" w="31px" h="30px" fontSize="18px" bg="rgba(0,0,0, .08)" borderRadius="100%">{bookNo === index && <Flex bg="#0e8a3a" w="15px" h="15px" borderRadius="100%"></Flex>}</Flex>
                                        <Flex flex="1" direction="column" ml="4" borderBottom="1px solid rgb(150,150,150)" pb="3">
                                            <Text mb="1" fontSize={["14px", "17px" ]} fontWeight="500">{item}</Text>
                                        </Flex>
                                    </Flex>
                                ))}
                            </Flex>
                            :
                            screen === 2 ?
                            <Flex direction="column" align="center">                           
                                <Calendar onChange={setData3} value={data3} />
                            </Flex>
                            :
                            screen === 3 ?
                            <Flex flexWrap="wrap" align="center" justify="space-between">  
                                {["8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30",].map((item, index) => (
                                    <Flex key={index} onClick={() => {
                                        setData4(item)
                                        setBookNo(index)
                                    }} fontWeight="600" w="48%" mb="3" border={bookNo === index ? "2px solid #0e8a3a" : "2px solid #000"} color={bookNo === index ? "#0e8a3a" : "#000"} cursor="pointer" justify="center" px="4" py="2">{item}</Flex>
                                ))}
                            </Flex>
                            :
                            screen === 4 ?
                            <Flex direction="column" align="center">     
                                <Text mb="5" w="100%"><Text mb="2" fontWeight="600" fontSize="13px">Enter your fullname</Text><input style={{ width: "100%", border: "2px solid #000", padding: "10px 20px", borderRadius: "8px", background: "transparent" }} onInput={(e) => setData5(e.target.value)} type="text" /></Text>     
                                <Text mb="5" w="100%"><Text mb="2" fontWeight="600" fontSize="13px">Enter your email</Text><input style={{ width: "100%", border: "2px solid #000", padding: "10px 20px", borderRadius: "8px", background: "transparent" }} onInput={(e) => setData6(e.target.value)} type="email" /></Text>     
                                <Text mb="5" w="100%"><Text mb="2" fontWeight="600" fontSize="13px">Enter your phone number</Text><input style={{ width: "100%", border: "2px solid #000", padding: "10px 20px", borderRadius: "8px", background: "transparent" }} onInput={(e) => setData7(e.target.value)} type="tel" /></Text>
                            </Flex>
                            :
                            <Flex direction="column" align="center" h="100%" justify="center" fontSize="18px">
                                <Image mb="7" src={star} w="150px" />
                                <Text fontWeight="600" mb="2">Successfully Booked!!</Text>
                                <Text textAlign="center" px={["2", "10%"]} fontSize={["14px", "15px"]}>Welcome <Text as="span" textTransform="capitalize" fontWeight="500">{data5}</Text>. Your appointment is set with <Text as="span" fontWeight="500">Dr.Haroun</Text> on <Text as="span" fontWeight="500">{data3.getDate()}-{data3.getMonth().length < 2 ? <>0{data3.getMonth()}</> : <>{data3.getMonth()}</>}-{data3.getFullYear()}</Text> at <Text as="span" fontWeight="500">{data4}</Text>. We will reach out to you via your email or your phone number</Text>
                                <Flex align="center" mt="5">
                                    <Text w="50px" h="2px" bg="#000" mr="2"></Text>
                                    <Flex border="2px solid #000" padding={["10px 30px", "10px 20px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => history.push("/")}>{loading ? <Spinner color="#000" emptyColor="lightgrey" /> : <>Ok<Text ml="2">➜</Text></>}</Flex>
                                </Flex>
                            </Flex>
                        }

                        {screen !== 5 && <Flex mt="auto" w="100%">
                            <Flex w="100%" justify="space-between" mt="10">
                                {screen !== 0 &&
                                    <Flex align="center">
                                        <Flex border="2px solid #000" padding={["10px 30px", "10px 20px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => backFunc()}>
                                            <Text mr="2" transform="rotate(180deg)">➜</Text> Back
                                        </Flex>
                                        <Text w="50px" h="2px" bg="#000" ml="2" display={["none", "block"]}></Text>
                                    </Flex>
                                }
                                
                                <Flex align="center" ml="auto">
                                    <Text w="50px" h="2px" bg="#000" mr="2" display={["none", "block"]}></Text>
                                    <Flex border="2px solid #000" padding={["10px 30px", "10px 20px"]} cursor="pointer" borderRadius="30px" color="#000" fontWeight="700" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => nextFunc()}>{loading ? <Spinner color="#000" emptyColor="lightgrey" /> : <>Next<Text ml="2">➜</Text></>}</Flex>
                                </Flex>
                            </Flex>
                        </Flex>}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}