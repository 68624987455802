import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import logo from "./logo.png"
import { useHistory, useLocation } from "react-router-dom"

export default function Footer() {
    const location = useLocation()
    const history = useHistory()

    return (
        <>
            {
                location.pathname.includes("our-services") || location.pathname.includes("booking") ?
                <></>
                :
                <Flex w="100%" bg="rgb(250,250,252)" direction="column" px={["6", "8%"]} pt={["60px", "80px"]} pb={["30px", "50px"]}>
                    <Flex w="100%" justify="space-between" direction={["column", "row"]}>
                        <Flex w={["100%", "38%"]} direction="column">
                            <Flex align="center" mb="5">
                                <Image src={logo} h="60px" />
                                <Text fontSize="20px" ml="3"><Text fontWeight="700" as="span">Love For</Text> Health</Text>
                            </Flex>

                            <input id="txt1" style={{ width: "100%", border: "1px solid #000", padding: "14px 20px", borderRadius: "30px" }} placeholder="Enter a feedback or reach out to us" />
                            <Flex mt="4"><Flex bg="#000" padding="14px 20px" cursor="pointer" borderRadius="30px" color="#fff" fontWeight="600" align="center" justify="center" transition="150ms ease-in-out" _hover={{ transform: "translate(4px, 0px)" }} onClick={() => {
                                var a = document.getElementById('txt1').value
                                if(a !== "") {
                                    window.open("mailto: loveforhealthlho@gmail.com?subject=feedback&body="+a)
                                }
                            }}>Send eMail <Text ml="2">➜</Text></Flex></Flex>
                        </Flex>

                        <Text display={["block", "none"]} h="1px" bg="rgb(202, 202, 205)" mt="8"></Text>


                        <Flex w="auto" direction="column" mt={["5", "0"]}>
                            <Text fontSize="16px" mb="5" fontWeight="600">Quick Links</Text>
                            {["About Us", "Services", "Contact Us", "Book an Appointment"].map((item, index) => (
                                <Text fontSize={["13px", "14px"]} cursor="pointer" color="rgb(102, 112, 133)" mb="5" key={index} _hover={{ color: "#0e8a3a" }} transition="200ms ease-in-out" onClick={() => history.push(index === 0 ? "/about-us" : index === 1 ? "/our-services" : index === 2 ? "/contact-us" : "/booking")}>{item}</Text>
                            ))}
                        </Flex>


                        <Flex w="auto" mt={["5", "0"]} direction="column">
                            <Text fontSize="16px" mb="5" fontWeight="600">Offers</Text>
                            {["Articles", "Our Proposal", "Donations"].map((item, index) => (
                                <Text fontSize={["13px", "14px"]} cursor="pointer" color="rgb(102, 112, 133)" mb="5" key={index} _hover={{ color: "#0e8a3a" }} transition="200ms ease-in-out"  onClick={() => history.push(index === 0 ? "/articles" : index === 1 ? "/donations" : "/donations")}>{item}</Text>
                            ))}
                        </Flex>


                        <Flex w="auto" direction="column" mt={["5", "0"]}>
                            <Text fontSize="16px" mb="5" fontWeight="600">Get in touch</Text>
                            <Flex align="center" mb="8">
                                <Flex fontSize="20px" justify="center" align="center" px="5" w="49px" h="48px" mr={["12", "8"]} bg="rgba(105, 105, 105, .1)" borderRadius="30px" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} fontWeight="600" onClick={() => window.open("https://twitter.com/Drweloveu")}>
                                    <Text><i className="mdi mdi-twitter"></i></Text>
                                </Flex>
                                <Flex fontSize="20px" justify="center" align="center" px="5" w="49px" mr={["12", "8"]} h="48px" bg="rgba(105, 105, 105, .1)" borderRadius="30px" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} fontWeight="600" onClick={() => window.open("https://instagram.com/drweloveu?igshid=NTc4MTIwNjQ2YQ==")}>
                                    <Text><i className="mdi mdi-instagram"></i></Text>
                                </Flex>
                                <Flex fontSize="20px" justify="center" align="center" px="5" w="49px" h="48px" bg="rgba(105, 105, 105, .1)" borderRadius="30px" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} fontWeight="600" onClick={() => window.open("https://t.me/drweloveu")}>
                                    <Text><i className="mdi mdi-telegram"></i></Text>
                                </Flex>
                            </Flex>


                            <Text fontSize={["13px", "14px"]} cursor="pointer" color="rgb(102, 112, 133)" mb="5" _hover={{ color: "#0e8a3a" }} transition="200ms ease-in-out" onClick={() => window.open("tel: +16148978196", "_SELF")}>+16148978196</Text>

                            <Text fontSize={["13px", "14px"]} cursor="pointer" color="rgb(102, 112, 133)" mb="5" _hover={{ color: "#0e8a3a" }} transition="200ms ease-in-out" onClick={() => window.open("mailto: loveforhealthlho@gmail.com", "_SELF")}>loveforhealthlho@gmail.com</Text>

                            <Text fontSize={["13px", "14px"]} color="rgb(102, 112, 133)" mb="5">Ohio, USA</Text>
                        </Flex>
                    </Flex>



                    <Flex color="rgb(102, 112, 133)" w="100%" justify="space-between" borderTop="1px solid rgb(202, 202, 205)" mt="8" pt="12" align="center" fontSize={["13px", "14px"]}>
                        <Text>&copy;  2023 Love For Health.</Text>
                        <Text>
                            <Text display={["none", "inline"]} color="rgb(102, 112, 133)" cursor="pointer" _hover={{ color: "#0e8a3a" }} transition="200ms ease-in-out">Privacy Policy</Text>
                            <Text ml="8" as="span" color="rgb(102, 112, 133)" cursor="pointer" _hover={{ color: "#0e8a3a" }} transition="200ms ease-in-out">All rights reserved.</Text>
                        </Text>
                    </Flex>
                </Flex>
            }
        </>
    )
}